<template>
  <div>
    <octo-table
      show-filters
      enable-export
      :export-action="endpoints.DATATABLES.classrooms + '/export/pending-scheduled'"
      :action="endpoints.DATATABLES.classrooms + '/pending-scheduled'"
      :fields="fields"
      :show-id="false"
      :filters="filters"
      :name="name"
      :enable-add="true"
      @onResetFilters="filters ={}"
      ref="octoTable"
      :order-by-items="['expected_start_date', 'code']"
      @onAdd="addNewClassroom"
    >

      <template v-slot:status="data">
        <badge :type="data.row.status | classroomStatusColor" class="text-uppercase">
          {{ $t('didactics.classroom_status.' + data.row.status) }}
        </badge>
      </template>

      <template v-slot:type="data">
        <badge :type="data.row.type | classroomTypeColor" class="text-uppercase">
          {{ data.row.type }}
        </badge>
      </template>

      <template v-slot:code="data">
        <div>{{ data.row.code }}</div>
      </template>

      <template v-slot:studentsCount="data">
        {{
          studentsNumber(
            data.row.students ? data.row.students.length : 0,
            data.row.courses ? data.row.courses[0] : null)
        }}

      </template>

      <template v-slot:expected_start_date="data">
        {{ data.row.expected_start_date | date }}
      </template>

      <template v-slot:location="data">
        <div class="text-uppercase">{{ getLocationName(data.row.location_id) }}</div>
      </template>

      <template v-slot:schedulation="data">
        <div class="small">
          {{ data.row.schedulation }}
        </div>
      </template>

      <template v-slot:courses="data">
        <div class="text-truncate">
          {{ getCourseCodes(data.row) }}
        </div>
      </template>

      <template v-slot:tutorsCount="data">
        <div v-if="data.row.type !== classroomTypes.certificate.value">
          {{ data.row.tutors ? data.row.tutors.length : 0 }}
        </div>
      </template>

      <template v-slot:actions="data">
        <div class="d-flex justify-content-end">
          <base-button
            class="mx-1"
            size="sm"
            link
            icon
            @click="$router.push({name: 'didactics.classrooms.pending.show', params: {id: data.row.id}})"
          >
            <octo-icon icon="right-arrow"/>
          </base-button>
        </div>
      </template>

      <template slot="custom-filters">
        <div class="col-12">
          <base-input :label="$t('datatable.courses')">
            <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.courseIds"
            >
              <el-option
                v-for="course in courses"
                class="select-default text-uppercase"
                :value="course.id"
                :label="course.code + ': ' + course.title"
                :key="course.id"
              />
            </el-select>
          </base-input>
        </div>
        <div class="col-12 col-md-6">
          <base-input :label="$t('fields.location')" class="mb-0">
            <el-select
              class="select-default text-uppercase"
              filterable
              clearable
              multiple
              :placeholder="$t('common.select')"
              v-model="filters.locationIds"
            >
              <el-option
                class="select-default text-uppercase"
                v-for="(location, index) in locations"
                :value="location.id"
                :label="location.alias"
                :key="`location-${index}`"
              />
            </el-select>
          </base-input>
        </div>
        <div class="col-12 col-md-6">
          <base-input :label="$t('datatable.tutors')">
            <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.tutorIds"
            >
              <el-option
                v-for="option in tutorOptions"
                class="select-default text-uppercase"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              />
            </el-select>
          </base-input>
        </div>
        <div class="col-12 col-md-6">
          <base-input :label="$t('datatable.status')">
            <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.statuses"
            >
              <el-option
                v-for="status in classroomStatuses"
                class="select-default text-uppercase"
                :value="status"
                :label="$t('didactics.classroom_status.' + status)"
                :key="status"
              />
            </el-select>
          </base-input>
        </div>
        <div class="col-12 col-md-6">
          <base-input :label="$t('datatable.type')">
            <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.types"
            >
              <el-option
                v-for="type in classroomTypes"
                class="select-default text-uppercase"
                :value="type.value"
                :label="$t('common.' + type.value)"
                :key="type.value"
              />
            </el-select>
          </base-input>
        </div>
      </template>
    </octo-table>
    <modal
      bodyClasses="px-2"
      centered
      :show.sync="showModal"
    >
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('page.classroom_new') }}
      </h5>
      <classroom-pending-form :key="`form-${formKey}`" ref="classroomPendingForm"/>
      <template slot="footer">
        <base-button link @click="saveNewClassroom">
          <span class="text-capitalize">{{ $t('common.save') }}</span>
        </base-button>
      </template>
    </modal>

  </div>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Badge from "@/components/Badge";
import {Select, Option} from "element-ui";
import {mapGetters} from "vuex";
import {permissionService} from "@/util/permission-service";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import Modal from "@/components/Modal";
import ClassroomPendingForm from "@/pages/Didactics/forms/ClassroomPendingForm";
import ClassroomStatuses from "@/pages/Didactics/resources/classroomStatuses";
import ClassroomTypes from "@/pages/Didactics/resources/classroomTypes";

export default {
  name: "ClassroomPendingDatatable",
  components: {
    ClassroomPendingForm,
    LabelThemeComponent,
    Badge,
    OctoIcon,
    OctoTable,
    [Select.name]:
    Select, [Option.name]: Option,
    Modal
  },
  data() {
    return {
      name: 'ClassroomPendingDatatable',
      endpoints: endpoints,
      showModal: false,
      filters: {},
      formKey: 1,
      fields: [
        {prop: 'code', label: 'code', slot: true, fixed: true, width: 200},
        {prop: 'status', label: 'status', width: 200, slot: true, align: 'center'},
        {prop: 'type', label: 'type', slot: true, align: 'center', width: 150},
        {prop: 'courses', label: 'courses', minWidth: 300, slot: true},
        {prop: 'schedulation', label: 'schedulation', slot: true, width: 250},
        {prop: 'expected_start_date', label: 'expected_start_date', slot: true, align: 'center', width: 200},
        {prop: 'location', label: 'location', slot: true, width: 200},
        {prop: 'studentsCount', label: 'students', slot: true, align: 'center', width: 100},
        {prop: 'tutorsCount', label: 'tutors', slot: true, align: 'center', width: 100},
        {prop: 'actions', label: 'actions', width: 60, slot: true, fixed: 'right'},
      ],
      permissionService: permissionService,
      classroomStatuses: [
        ClassroomStatuses.pending.value,
        ClassroomStatuses.scheduled.value,
        ClassroomStatuses.ready_to_go.value
      ],
      classroomTypes: ClassroomTypes
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      locations: 'common/locations',
      courses: 'common/allCourses',
      getFilters: 'common/datatableFilters',
    }),

    tutorOptions() {
      const tutorUsers = this.$_.filter(this.users, user => {
        return this.$_.find(user.roles, role => {
          return role.name === permissionService.TUTOR_ROLE;
        })
      });

      return this.$_.map(tutorUsers, user => {
        return {
          label: (user?.lastname + ' ' + user?.firstname) || user.username,
          value: user.id
        }
      });
    },
  },
  methods: {
    getCourseCodes(classroom) {
      return this.$_.map(classroom.courses, course => course.code).join(', ');
    },

    studentsNumber(studentsNumber, course) {
      return studentsNumber
        + (course.maximum_participants ? ` di ${course.maximum_participants}` : '')
    },

    addNewClassroom() {
      this.showModal = true;
      this.formKey++;
    },

    async saveNewClassroom() {
      try {
        const data = await this.$refs.classroomPendingForm.validate();

        this.$fullLoading.show();

        const resp = await this.$api.post(
          endpoints.CLASSROOM_STORE,
          data
        );

        this.showModal = false;
        this.$fullLoading.hide();
        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        await this.$router.push({name: 'didactics.classrooms.pending.show', params: {id: resp?.data?.data?.id}})
      } catch (e) {
        console.log(e);
        this.$fullLoading.hide();
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      }
    },

    getLocationName(locationId) {
      return this.$_.find(this.locations, location => location.id === locationId)?.alias;
    }
  }
}
</script>

<style scoped>

</style>
