<template>
  <ValidationObserver ref="classroomForm">
    <ul class="list-group list-group-flush">
      <list-group-item-component :label="$t('fields.code')" :label-width="labelWidth">
        <template slot="value">
          <ValidationProvider name="code" class="flex-grow-1" rules="required" v-slot="{ passed, failed, errors }">
            <base-input required
                        v-model="code"
                        type="text"
                        class="mb-0"
                        :error="errors[0]"
                        :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]"
            />
          </ValidationProvider>
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('fields.schedulation')" :label-width="labelWidth">
        <template slot="value">
          <base-input v-model="schedulation" type="text" class="mb-0 flex-grow-1"/>
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('fields.type')" :label-width="labelWidth">
        <template slot="value">
          <ValidationProvider name="type" class="flex-grow-1" rules="required" v-slot="{ passed, failed, errors }">
            <base-input required
                        class="mb-0"
                        :error="errors[0]"
                        :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]">
              <el-select
                class="select-default text-uppercase"
                filterable
                :placeholder="$t('fields.type')"
                v-model="type"
              >
                <el-option
                  class="select-default text-uppercase"
                  v-for="classroomType in classroomTypes"
                  :value="classroomType.value"
                  :label="$t('didactics.classroomType.' + classroomType.value)"
                  :key="`classroomType-${classroomType.value}`"
                >
                </el-option>
              </el-select>
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>
      <list-group-item-component
        v-if="type==='course'"
        :label="$t('fields.course')"
        :label-width="labelWidth">
        <template slot="value">
          <base-input class="mb-0 flex-grow-1">
            <el-select
              class="select-default text-uppercase"
              filterable
              :placeholder="$t('fields.type_to_search')"
              v-model="courseId"
            >
              <el-option
                class="select-default text-uppercase"
                v-for="course in courses"
                :value="course.id"
                :label="course.code + ': ' + course.title"
                :key="`course-${course.id}`"
              >
              </el-option>
            </el-select>
          </base-input>
        </template>
      </list-group-item-component>

      <list-group-item-component
        v-if="type==='workshop'"
        :label="$t('fields.workshop')"
        :label-width="labelWidth">
        <template slot="value">
          <base-input class="mb-0 flex-grow-1">
            <el-select
              class="select-default text-uppercase"
              filterable
              :placeholder="$t('fields.type_to_search')"
              v-model="workshopId"
            >
              <el-option
                class="select-default text-uppercase"
                v-for="workshop in workshops"
                :value="workshop.id"
                :label="workshop.code + ': ' + workshop.title"
                :key="`workshop-${workshop.id}`"
              >
              </el-option>
            </el-select>
          </base-input>
        </template>
      </list-group-item-component>

      <template v-if="type==='certificate'">

        <list-group-item-component
          :label="$t('fields.certificationBody')"
          :label-width="labelWidth">
          <template slot="value">
            <base-input
              v-model="certificationBody"
              type="text"
              class="mb-0 flex-grow-1"
            />
          </template>
        </list-group-item-component>

        <list-group-item-component
          :label="$t('fields.certificates')"
          :label-width="labelWidth">
          <template slot="value">
            <base-input class="mb-0 flex-grow-1">
              <el-select
                class="select-default text-uppercase"
                multiple
                filterable
                collapse-tags
                value-key="id"
                :placeholder="$t('fields.type_to_search')"
                v-model="certificateIds"
              >
                <el-option
                  class="select-default text-uppercase"
                  v-for="certificate in certificates"
                  :value="certificate.id"
                  :label="certificate.code + ': ' + certificate.title"
                  :key="`certificate-${certificate.id}`"
                >
                </el-option>
              </el-select>
            </base-input>
          </template>
        </list-group-item-component>
        <list-group-item-component
          v-for="(certificate, index) in selectedCertificates"
          v-bind:key="`selected-certificates-${index}`"
        >
          <template slot="label">
            <div>
              <div class="small">{{ certificate.title }}</div>
              <div class="small text-uppercase">Code:
                <label-theme-component>{{ certificate.code }}</label-theme-component>
              </div>
            </div>
          </template>
        </list-group-item-component>


      </template>


    </ul>
  </ValidationObserver>
</template>

<script>
import {Option, Select} from "element-ui";
import {mapGetters} from "vuex";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import ClassroomTypes from "@/pages/Didactics/resources/classroomTypes";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "ClassroomPendingForm",
  components: {LabelThemeComponent, ListGroupItemComponent, [Select.name]: Select, [Option.name]: Option},
  data() {
    return {
      code: '',
      schedulation: '',
      type: null,
      courseId: null,
      workshopId: null,
      certificateIds: [],
      certificationBody: '',
      labelWidth: 140,
      classroomTypes: ClassroomTypes
    }
  },
  computed: {
    ...mapGetters({
      courses: 'common/courses',
      workshops: 'common/workshops',
      certificates: 'common/certificates',
    }),

    selectedCertificates() {
      if (this.certificateIds?.length > 0) {
        return this.$_.map(this.certificateIds, (id) => {
          return this.$_.find(this.certificates, {'id': id});
        });
      }
      return [];
    }
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.classroomForm.validate()
          .then((success) => {
            if (success) {

              if ((this.type === this.classroomTypes.course.value && !this.courseId)
                || (this.type === this.classroomTypes.workshop.value && !this.workshopId)
                || (this.type === this.classroomTypes.certificate.value && !this.certificateIds?.length)
              ) {
                reject()
              }

              resolve({
                code: this.code,
                schedulation: this.schedulation,
                type: this.type,
                certificationBody: this.certificationBody,
                courseId: this.courseId,
                workshopId: this.workshopId,
                certificateIds: this.certificateIds
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  }
}
</script>

<style scoped>

</style>
