<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <classroom-pending-datatable ref="classroomPendingDatatable"/>
      </div>
    </div>
  </div>
</template>

<script>

import ClassroomPendingDatatable from "./datatables/ClassroomPendingDatatable";
import ClassroomPendingForm from "@/pages/Didactics/forms/ClassroomPendingForm";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import Modal from "@/components/Modal";
import {mapMutations} from "vuex";
import IndexClassroomPendingPageHelper from "@/components/Helpers/IndexClassroomPendingPageHelper";

export default {
  name: "IndexClassroomPendingPage",
  components: {ClassroomPendingForm, Modal, OctoHeaderButton, ClassroomPendingDatatable},
  data() {
    return {
      headerButtons: [
        {
          label: 'add',
          onClick: 'onAddClassroom'
        }
      ],
      formKey: 0,
      showModal: false,
      helperComponent: IndexClassroomPendingPageHelper
    }
  },
  mounted() {
    this.setHelper(this.helperComponent);
  },
  methods: {
    ...mapMutations({
      setHelper: 'common/setHelper',
    }),
  },
  beforeDestroy() {
    this.setHelper();
  }
}
</script>

<style scoped lang="scss">

</style>
